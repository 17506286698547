<template>
  <div class="decorator-divider">
    <div class="divider" />
    <img
      :style="bg ? colorBg : ''"
      src="@/assets/icons/logo-icon-gray.svg"
      alt="logo simple clover"
    />
  </div>
</template>
<script>
export default {
  props: {
    bg: {
      type: Boolean,
      required: false,
      default: false,
    },
    colorBg: {
      type: String,
      required: false,
      default: 'background-color: #FFFFFF;',
    },
  },
}
</script>

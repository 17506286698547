<template>
  <div class="container-newslatter">
    <divider-page :bg="true" :color-bg="'background-color: #F8E5F1;'" />
    <div class="container-data-newslatter">
      <div v-if="!sended">
        <h3 style="color: black">
          {{ tituloNewslatter }}
        </h3>
        <p class="mb-4" style="color: black">
          {{ descripcionNewslatter }}
        </p>
      </div>
      <div v-if="!sended" class="container-form">
        <div class="w-100 position-relative input-simple-clover">
          <label
            class="label-input"
            :style="correo ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >
            Correo electrónico
          </label>
          <input
            id="email"
            v-model="correo"
            type="email"
            style="min-height: 50px"
            class="payment-input w-100"
            :style="validateEmailNewslatter ? 'border-color: #FF5151' : ''"
            placeholder="Correo electrónico"
            @focus="
              focusInput = true
              validateEmailNewslatter = false
            "
          />
          <span v-if="validateEmailNewslatter" class="inavalida-input">
            {{ correo ? 'Correo inválido' : 'Campo requerido' }}
          </span>
        </div>
        <div
          :class="focusInput ? 'display-form__true' : ''"
          class="pt-4 display-form"
        >
          <div class="mb-4 w-100 position-relative input-simple-clover">
            <label
              class="label-input"
              :style="name ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >
              Nombre completo
            </label>
            <input
              id="name"
              v-model="name"
              type="name"
              style="min-height: 50px"
              class="payment-input w-100"
              :style="validateNameNewslatter ? 'border-color: #FF5151' : ''"
              placeholder="Nombre completo"
              @focus="validateNameNewslatter = false"
            />
            <span v-if="validateNameNewslatter" class="inavalida-input">
              {{ name ? 'Nombre inválido' : 'Campo requerido' }}
            </span>
          </div>
          <div class="mb-4 w-100 position-relative input-simple-clover">
            <label
              class="label-input"
              :style="tel ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >
              celular
            </label>
            <input
              v-model="tel"
              class="payment-input w-100"
              placeholder="Celular"
              type="tel"
              :style="validateTelNewslatter ? 'border-color: #FF5151' : ''"
              name="celular"
              @focus="validateTelNewslatter = false"
            />
            <span v-if="validateTelNewslatter" class="inavalida-input">
              {{ tel ? 'Celular inválido' : 'Campo requerido' }}
            </span>
          </div>
          <p>Fecha de cumpleaños</p>
          <div class="select-date-newslatter-footer mb-4 input-simple-clover">
            <div
              class="col-12 col-md-6 position-relative pe-0 pe-md-2 input-simple-clover"
            >
              <label
                class="label-input"
                :style="day ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >
                Día
              </label>
              <select
                v-model="day"
                class="payment-input w-100"
                :style="
                  !day && validateDateNewslatter ? 'border-color: #FF5151' : ''
                "
              >
                <option :value="null">Día</option>
                <option :value="'01'">1</option>
                <option :value="'02'">2</option>
                <option :value="'03'">3</option>
                <option :value="'04'">4</option>
                <option :value="'05'">5</option>
                <option :value="'06'">6</option>
                <option :value="'07'">7</option>
                <option :value="'08'">8</option>
                <option :value="'09'">9</option>
                <option :value="'10'">10</option>
                <option :value="'11'">11</option>
                <option :value="'12'">12</option>
                <option :value="'13'">13</option>
                <option :value="'14'">14</option>
                <option :value="'15'">15</option>
                <option :value="'16'">16</option>
                <option :value="'17'">17</option>
                <option :value="'18'">18</option>
                <option :value="'19'">19</option>
                <option :value="'20'">20</option>
                <option :value="'21'">21</option>
                <option :value="'22'">22</option>
                <option :value="'23'">23</option>
                <option :value="'24'">24</option>
                <option :value="'25'">25</option>
                <option :value="'26'">26</option>
                <option :value="'27'">27</option>
                <option :value="'28'">28</option>
                <option :value="'29'">29</option>
                <option :value="'30'">30</option>
                <option :value="'31'">31</option>
              </select>
              <span
                v-if="!day && validateDateNewslatter"
                class="inavalida-input"
              >
                {{ day ? 'Día inválido' : 'Campo requerido' }}
              </span>
            </div>
            <div
              class="col-12 col-md-6 position-relative mt-4 mt-md-0 ps-0 ps-md-2 input-simple-clover"
            >
              <label
                class="label-input"
                :style="month ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >
                Mes
              </label>
              <select
                v-model="month"
                class="payment-input w-100"
                :style="
                  !month && validateDateNewslatter
                    ? 'border-color: #FF5151'
                    : ''
                "
              >
                <option :value="null">Mes</option>
                <option :value="'01'">Enero</option>
                <option :value="'02'">Febrero</option>
                <option :value="'03'">Marzo</option>
                <option :value="'04'">Abril</option>
                <option :value="'05'">Mayo</option>
                <option :value="'06'">Junio</option>
                <option :value="'07'">Julio</option>
                <option :value="'08'">Agosto</option>
                <option :value="'09'">Septiembre</option>
                <option :value="'10'">Octubre</option>
                <option :value="'11'">Noviembre</option>
                <option :value="'12'">Diciembre</option>
              </select>
              <span
                v-if="!month && validateDateNewslatter"
                class="inavalida-input"
              >
                {{ month ? 'Mes inválido' : 'Campo requerido' }}
              </span>
            </div>
          </div>
          <div class="container-input-checkbox input-simple-clover">
            <input
              id="input-checkbox-tel"
              v-model="tyc"
              class="input-checkbox"
              type="checkbox"
              checkmark="checkmark"
            />
            <span class="checkmark"></span>
            <label
              for="input-checkbox-tel"
              style="font-size: 12px; color: #818181;"
            >
              He leído y acepto la
              <router-link
                style="color: black; text-decoration: none"
                target="_blank"
                :to="{
                  name: 'HabeasData',
                  params: {
                    politica: 'politica-de-tratamiento-de-datos',
                  },
                }"
              >
                <b>
                  Política de Privacidad y Tratamiento de datos personales.
                </b>
              </router-link>
              Acepto recibir novedades y promociones. Aplican
              <router-link
                style="color: black; text-decoration: none"
                target="_blank"
                :to="{
                  name: 'HabeasData',
                  params: {
                    politica: 'descuento-suscripcion',
                  },
                }"
              >
                <b>
                  Términos y Condiciones.
                </b>
              </router-link>
            </label>
          </div>
          <button
            :disabled="!tyc"
            :style="tyc ? '' : 'opacity: 0.7; cursor: default'"
            class="mt-3 bnt-primary w-100"
            @click="validateService()"
          >
            <b>Suscribirme</b>
          </button>
        </div>
        <div v-if="errorSolicitud" class="w-100">
          <span class="px-0 px-md-4 mx-auto text-error">
            {{ msgErrorSolicitud }}
          </span>
        </div>
      </div>
      <div v-else class="my-5">
        <img
          class="mb-3"
          style="z-index: 2"
          src="@/assets/icons/icon-check-newslatter.svg"
          alt="decorador-diamont"
        />
        <h2 class="mb-4">
          ¡Ahora haces parte de la exclusiva comunidad de clover!
        </h2>
        <p class="mb-5" style="color: black">
          Revisa tu correo electrónico en donde encontrarás tu cupón de
          descuento.
        </p>
        <button class="bnt-primary px-5" @click="sended = false">
          <b>Entendido</b>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import DividerPage from '@/components/Templates/DividerPage.vue'
import { mapState, mapActions } from 'vuex'
import { GA4SignUp } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  components: { DividerPage },
  data() {
    return {
      correo: '',
      name: '',
      tel: '',
      day: null,
      month: null,
      focusInput: false,
      tyc: false,
      sended: false,
      validateEmailNewslatter: false,
      validateNameNewslatter: false,
      validateDateNewslatter: false,
      validateTelNewslatter: false,
    }
  },
  computed: {
    ...mapState('newslatter', [
      'errorSolicitud',
      'msgErrorSolicitud',
      'tituloNewslatter',
      'descripcionNewslatter',
    ]),
    /* eslint-disable */
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validatePhone() {
      const re = /^3[0-9]{9}/
      return re.test(this.tel) && this.tel.length < 11
    },
  },
  methods: {
    ...mapActions('newslatter', ['sendSoliNewsLatter', 'getInfoNewsLatter']),
    async validateService() {
      if (
        this.validateEmail &&
        this.validatePhone &&
        this.name.length > 2 &&
        this.day && this.month
      ) {
        this.$store.state.loaderClover = true
        let data = {
          email: this.correo,
          name: this.name,
          day: this.day,
          month: this.month,
          sendSMS: 1,
          tel: this.tel,
        }
        await this.sendSoliNewsLatter(data).then(() => {
          this.$store.state.loaderClover = false
          if (!this.errorSolicitud) {
            GA4SignUp('newslatter')
            this.activeStepNewslatter = 4
            sendFBQEvent(this, 'Subscribe', {})
            document.cookie = 'email=' + this.correo
            this.correo = ''
            this.name = ''
            this.tel = ''
            this.day = null
            this.month = null
            this.sended = true
            this.focusInput = false
          }
        })
      } else {
        this.validateEmailNewslatter = !this.validateEmail
        this.validateNameNewslatter = !(this.name.length > 2)
        this.validateDateNewslatter = !(this.day && this.month)
        this.validateTelNewslatter = !this.validatePhone
      }
    },
  },
  async mounted() {
    await this.getInfoNewsLatter()
  },
}
</script>
<style>
.container-newslatter {
  margin-bottom: 1rem;
  background-color: #F8E5F1;
  padding: 2rem;
}
.container-newslatter .container-data-newslatter {
  max-width: 830px;
  padding-top: 2rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: black;
}
.container-newslatter .container-data-newslatter .container-form {
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
}
.display-form {
  transition: all 0.5s linear 0s;
  overflow: hidden;
  height: 0;
}
.display-form__true {
  height: 450px;
}
.select-date-newslatter-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.select-date-newslatter-footer select {
  width: calc(50% - 0.5rem);
  max-width: 200px;
  min-height: 50px;
}
@media (max-width: 768px) {
  .display-form__true {
    height: 550px;
  }
  .select-date-newslatter-footer select {
    max-width: none;
  }
}
</style>

export default {
  productsCategory: null,
  vitrinaMasVisto: null,
  productDetail: null,
  estrategiaCatlogo: null,
  infoCatalogo: null,
  filters: null,
  vitrinaRelacionados: null,
  disponibilidadProduct: null,
  errorvaloracion: false,
  valoracionesProducto: null,
  infoEnvio: null,
  productModal: null,
  dataCatalogoAnalytics: null,
  showSizeProduct: false,
}

<template>
  <div>
    <a
      href="https://api.whatsapp.com/send?phone=573208743430&text=¡Hola! Estoy interesado(a) en comprar."
      target="_blank"
    >
      <div id="btn-whatsapp" class="content-whatshapp">
        <img
          width="30"
          height="30"
          src="@/assets/icons/icon-whatsapp-white.svg"
        />
      </div>
    </a>
    <a
      class="text-decoration-none"
      target="_blank"
      href="https://api.whatsapp.com/send?phone=573208743430&text=¡Hola! Estoy interesado(a) en comprar."
      @click="open = false"
    >
      <div
        class="container-text-help"
        :style="
          closeText ? 'opacity: 1; bottom: 6rem;' : 'opacity: 0; bottom: 2rem;'
        "
      >
        ¿Necesitas ayuda?
      </div>
    </a>
    <div
      :style="
        closeText ? 'opacity: 1; bottom: 6.7rem;' : 'opacity: 0; bottom: 2rem;'
      "
      class="close-text-help"
      @click="closeText = false"
    >
      x
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      closeText: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.closeText = true
    }, 10000)
  },
}
</script>
<style lang="scss">
.content-whatshapp {
  position: fixed;
  z-index: 10;
  bottom: 2rem;
  right: 2rem;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00b66c;
  box-shadow: 0 0 16px #00000040;
  border-radius: 50%;
  transition: all 0.2s linear;
}
.container-text-help {
  position: fixed;
  z-index: 10;
  right: 2.5rem;
  transition: all 0.2s linear;
  background-color: #fff;
  border: 2px solid #000;
  padding: 0.6rem 0.9rem;
  width: 170px;
  text-align: center;
  border-radius: 50px;
  box-shadow: 2px 3px #00b66c;
  color: #000;
}
.close-text-help {
  position: fixed;
  cursor: pointer;
  z-index: 10;
  right: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9da9b9;
  font-weight: bold;
  line-height: 1.1;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 50%;
  background-color: black;
}
</style>

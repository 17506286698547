import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles.scss'
import VueFacebookPixel from 'vue-facebook-pixel'
import { VueReCaptcha } from 'vue-recaptcha-v3'

// import component
import 'vue-slider-component/dist-css/vue-slider-component.css'
// import theme
import 'vue-slider-component/theme/default.css'
require('./plugins')

Vue.config.productionTip = false
Vue.use(VueFacebookPixel)
Vue.use(VueReCaptcha, { siteKey: '6LcOf9wbAAAAAOr46wjNv6sbuY595SQId6d_vY4p' })

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

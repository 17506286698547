<template>
  <b-modal
    id="modal-products-sold-out"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div v-if="productsCart" class="container-modal-sold-out">
      <div>
        <img
          src="@/assets/icons/icon-alert_triangle.svg"
          class="d-flex mx-auto mb-4"
        />
        <h4 class="title-sold-out">
          {{
            productsCart.find(p => p.status == 1)
              ? 'Algunos productos ya no están disponibles'
              : 'Los productos ya no están disponibles'
          }}
        </h4>
        <p class="descripcion-sold-out">
          {{
            productsCart.find(p => p.status == 1)
              ? 'Lo sentimos, algunos productos se han agotado y se han removidos de tu bolsa.'
              : 'Lo sentimos, todos los productos se han agotado y se han removidos de tu bolsa.'
          }}
        </p>
      </div>
      <div v-if="getProductsSoldOut.length < productsCart.length" class="mb-4">
        <div
          v-for="(product, index) in getProductsSoldOut"
          :key="index"
          class="product-sold-out"
        >
          <div class="container-img">
            <img :src="product.referencia.imagenes[0]" alt="" />
            <span>Agotado</span>
          </div>
          <div class="container-info-product-sold-out col">
            <p>{{ product.producto_titulo }}</p>
            <p>
              <span class="font-weight-bold"
                >${{
                  formatPrice(
                    product.producto_precio
                      ? product.producto_precio
                      : product.precio,
                  )
                }}</span
              >
              <del
                v-if="
                  (product.producto_precio_antes &&
                    product.producto_precio_antes > 0) ||
                    (product.precio_antes && product.precio_antes > 0)
                "
                style="color: #ADB2B7; font-weight: bold; padding-left: .5rem;"
                >${{
                  formatPrice(
                    product.producto_precio_antes
                      ? product.producto_precio_antes
                      : product.precio_antes,
                  )
                }}</del
              >
            </p>
            <p>
              Talla: {{ product.referencia.talla.name }} -
              {{ product.referencia.color_nombre }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="!dataCart.cliente_correo"
        class="container-input-checkbox mb-4"
      >
        <input
          id="input-checkbox-tel"
          v-model="sendEmail"
          class="input-checkbox"
          type="checkbox"
          checkmark="checkmark"
        />
        <span class="checkmark"></span>
        <label for="input-checkbox-tel">
          <b>Avísame cuando esté disponible.</b>
        </label>
      </div>
      <div v-if="sendEmail" class="input-simple-clover mb-4">
        <label
          class="label-input"
          :class="validEmail ? 'invalid' : ''"
          :style="email ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Correo electrónico</label
        >
        <input
          id="email"
          v-model="email"
          :class="validEmail ? 'invalid-error' : ''"
          placeholder="Correo electrónico"
          type="email"
          autocomplete="email"
          @blur="validateEmail()"
          @change="validateEmail()"
          @focus="validEmail = false"
        />
        <span v-if="validEmail" class="inavalida-input">{{
          email ? 'Correo invalido' : 'Campo requerido'
        }}</span>
      </div>
      <div class="d-flex flex-wrap">
        <button
          v-if="productsCart.find(p => p.status == 1)"
          class="col-10 mx-auto btn-primary-clover"
          :style="sendEmail && !validateCorreo ? 'opacity: .6' : ''"
          :disabled="sendEmail && !validateCorreo"
          @click="sendEmailProductSoldout()"
        >
          Continuar
        </button>
        <button
          v-else
          class="col-10 mx-auto btn-primary-clover"
          :style="sendEmail && !validateCorreo ? 'opacity: .6' : ''"
          :disabled="sendEmail && !validateCorreo"
          @click="sendEmailProductSoldout()"
        >
          Regresar a la tienda
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      email: '',
      show: true,
      sendEmail: false,
      validEmail: false,
    }
  },
  computed: {
    ...mapState('cart', ['dataCart', 'productsCart']),
    /* eslint-disable */
    validateCorreo() {
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    },
    getProductsSoldOut() {
      let products = this.productsCart.filter(p => p.status == 0)
      return products
    },
  },
  methods: {
    ...mapActions('cart', ['registerProductsSoldout']),
    validateEmail() {
      this.validEmail = !this.validateCorreo;
    },
    async sendEmailProductSoldout() {
      var tempEmail = this.sendEmail ? this.email : ''
      await this.registerProductsSoldout({ correo: tempEmail })
    },
    formatPrice(val) {
      return new Intl.NumberFormat('de-DE').format(val)
    },
  },
}
</script>
<style lang="scss">
#modal-products-sold-out {
  .modal-content {
    border-radius: 12px;
  }
  .container-modal-sold-out {
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
    .title-sold-out {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      margin-bottom: 1rem;
    }
    .descripcion-sold-out {
      text-align: center;
      border-bottom: 1px solid #EAF0FC;
      padding-bottom: 1rem;
    }
  }
}
.product-sold-out {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  border-bottom: 1px solid #EAF0FC;
  &:first-child {
    padding-top: 0;
  }
  .container-img {
    max-width: 90px;
    position: relative;
    margin-bottom: auto;
    padding-bottom: 1rem;
    img {
      width: 100%;
      margin-bottom: auto;
    }
    span {
      position: absolute;
      bottom: -5px;
      font-weight: bold;
      width: 100%;
      text-align: center;
      border-radius: 5px;
      left: 0;
      padding: .3rem;
      background-color: #FFF4DE;
    }
  }
  .container-info-product-sold-out {
    padding-left: 1rem;
    p {
      font-size: 16px;
      margin-bottom: .4rem;
      &:first-child {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>

import Vue from 'vue'
import $store from '../../store/index'
import { GA4AddToCart } from '../../GA4/events'

export async function addProductTocart({ state }, payload) {
  $store.state.loaderClover = true
  state.errorAddToCart = false
  state.msgErrorAddToCart = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  data.append('producto_sku', payload.sku)
  data.append('color', payload.color)
  data.append('talla_id', payload.tallaId)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/carrito/agregar_producto',
    data: data,
  })
    .then(response => {
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
      state.vptCart = response.data.productos_destacados
      state.productsEstrategia = response.data.productos_estrategias
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
      GA4AddToCart(payload.sku)
    })
    .catch(e => {
      state.errorAddToCart = true
      if (e.response.data.message) {
        state.msgErrorAddToCart = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorAddToCart = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function deleteProductTocart({ state }, payload) {
  $store.state.loaderClover = true
  state.errorAddToCart = false
  state.msgErrorAddToCart = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  data.append('id_carrito_producto', payload.idProduct)
  data.append('tipo', payload.tipo)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/carrito/borrar_producto',
    data: data,
  })
    .then(response => {
      if (response.data.productos) {
        state.dataCart = response.data.carrito
        state.productsCart = response.data.productos
        state.vptCart = response.data.productos_destacados
        state.productsEstrategia = response.data.productos_estrategias
        window.localStorage.setItem('tokenCart', response.data.carrito.token)
      } else {
        window.localStorage.removeItem('tokenCart')
        state.dataCart = null
        state.productsCart = null
        state.vptCart = null
      }
    })
    .catch(e => {
      state.errorAddToCart = true
      if (e.response.data.message) {
        state.msgErrorAddToCart = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorAddToCart = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function getProductsCart({ state }) {
  $store.state.loaderClover = true
  state.errorAddToCart = false
  state.loaderBag = true
  state.msgErrorAddToCart = ''
  let data = new FormData()
  data.append('token', window.localStorage.getItem('tokenCart'))
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/carrito/listar_productos',
    data: data,
  })
    .then(response => {
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
      state.vptCart = response.data.productos_destacados
      state.productsEstrategia = response.data.productos_estrategias
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
    })
    .catch(e => {
      state.errorAddToCart = true
      if (e.response.data.message) {
        state.msgErrorAddToCart = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
          if (property == 'token') {
            window.localStorage.removeItem('tokenCart')
            state.dataCart = null
            state.productsCart = null
            state.vptCart = null
          }
        }
        state.msgErrorAddToCart = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
      state.loaderBag = false
    })
}

export async function addCupon({ state }, payload) {
  $store.state.loaderClover = true
  state.errorCupon = false
  state.msgErrorCupon = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  data.append('cupon_codigo', payload.cupon)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/carrito/aplicar_cupon',
    data: data,
  })
    .then(response => {
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
      state.productsEstrategia = response.data.productos_estrategias
      state.productsCart = response.data.productos
    })
    .catch(e => {
      state.errorCupon = true
      if (e.response.data.message) {
        state.msgErrorCupon = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorCupon = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function deleteCupon({ state }) {
  $store.state.loaderClover = true
  state.errorCupon = false
  state.msgErrorCupon = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/carrito/remover_cupon',
    data: data,
  })
    .then(response => {
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
      state.productsEstrategia = response.data.productos_estrategias
      state.productsCart = response.data.productos
    })
    .catch(e => {
      state.errorCupon = true
      if (e.response.data.message) {
        state.msgErrorCupon = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorCupon = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function updateInfoCart({ state }, payload) {
  $store.state.loaderClover = true
  state.errorAddToCart = false
  state.msgErrorAddToCart = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  data.append('step', payload.step)
  data.append('cliente_correo', payload.cliente_correo)
  if (payload.step >= 2) {
    data.append('cliente_nombre', payload.cliente_nombre)
    data.append('cliente_apellidos', payload.cliente_apellidos)
    data.append('tipodoc', payload.tipodoc)
    data.append('cliente_documento', payload.cliente_documento)
    data.append('cliente_celular', payload.cliente_celular)
  }
  if (payload.step >= 3) {
    data.append('direccion_id', payload.direccion_id)
    data.append('cliente_direccion', payload.cliente_direccion)
    data.append('cliente_ciudad', payload.cliente_ciudad)
    data.append('cliente_departamento', payload.cliente_departamento)
    data.append('cliente_barrio', payload.cliente_barrio)
    data.append('observaciones', payload.observaciones)
  }
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/carrito/actualizar_informacion',
    data: data,
  })
    .then(response => {
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
    })
    .catch(e => {
      state.errorAddToCart = true
      if (e.response.data.message) {
        state.msgErrorAddToCart = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorAddToCart = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function RegisterPollCheckout({ state }, payload) {
  var form = new FormData()
  form.append('pedido_id', payload.pedido_id)
  form.append('puntaje', payload.puntaje)
  form.append('mensaje', payload.mensaje)
  state.errorSurvey = false
  state.msgErrorSurvey = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/pedidos/guardar_encuesta',
    data: form,
  })
    .then(() => {
      console.log(state != '')
    })
    .catch(e => {
      console.log(e)
      state.errorSurvey = true
      if (e.response.data.message) {
        state.msgErrorSurvey = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorSurvey = message
      }
    })
    .finally(() => {
      console.log('addProduct Done')
    })
}

export async function addGiftCard({ state }, payload) {
  // TODO: falta integrar correctamente este servicio, esta dando 500 y no se probo para mensajes de error.
  $store.state.loaderClover = true
  state.errorAddCuponCard = false
  state.msgErrorAddCuponCard = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  data.append('codigo', payload.codigo)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/carrito/aplicar_tarjeta_regalo',
    data: data,
  })
    .then(response => {
      state.dataCart = response.data.carrito
    })
    .catch(e => {
      state.errorAddCuponCard = true
      if (e.response.data.message) {
        state.msgErrorAddCuponCard = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorAddCuponCard = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function payWhitGifCard({ state }) {
  $store.state.loaderClover = true
  state.errorAddCuponCard = false
  state.msgErrorAddCuponCard = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  data.append('pedido_fe_dian', $store.state.esFE)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/pedidos/pago/giftcard',
    data: data,
  })
    .then(response => {
      state.dataSummaryPayment = response.data.response
    })
    .catch(e => {
      state.errorAddCuponCard = true
      if (e.response.data.message) {
        state.msgErrorAddCuponCard = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorAddCuponCard = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function removeGiftCard({ state }) {
  $store.state.loaderClover = true
  state.errorAddCuponCard = false
  state.msgErrorAddCuponCard = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/carrito/remover_tarjeta_regalo',
    data: data,
  })
    .then(response => {
      state.dataCart = response.data.carrito
    })
    .catch(e => {
      state.errorAddCuponCard = true
      if (e.response.data.message) {
        state.msgErrorAddCuponCard = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorAddCuponCard = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

//////////////////// Servicio productos agotados ////////////////////

export async function registerProductsSoldout({ state }, payload) {
  let form = new FormData()
  const token = window.localStorage.getItem('tokenCart')
  console.log(payload)
  form.append('token', token)
  form.append('cliente_correo', payload.correo)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/carrito/validar_productos',
    data: form,
  })
    .then(() => {
      location.reload()
      console.log(state)
    })
    .catch(function(error) {
      var message = ''
      var obj = error.response.data.errors
      for (const prop in obj) {
        message += `${obj[prop][0]} \n`
      }
      console.log('Error: ', message)
      return false
    })
}

import Vue from 'vue'
import $store from '../../store/index'

export async function addProductToFavorite({ state }, payload) {
  $store.state.loaderClover = true
  state.errorAddproductToFavorite = false
  state.msgErrorAddProductToFavorite = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenFavorite')
      ? window.localStorage.getItem('tokenFavorite')
      : '',
  )
  data.append('producto_sku', payload.sku)
  data.append('color', payload.color)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/whislist/add_product',
    data: data,
  })
    .then(response => {
      state.productsFavorite = response.data.productos
      window.localStorage.setItem('tokenFavorite', response.data.whislist.token)
    })
    .catch(e => {
      state.errorAddproductToFavorite = true
      if (e.response.data.message) {
        state.msgErrorAddProductToFavorite = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorAddProductToFavorite = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function deleteProductToFavorite({ state }, payload) {
  $store.state.loaderClover = true
  state.errorAddproductToFavorite = false
  state.msgErrorAddProductToFavorite = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenFavorite')
      ? window.localStorage.getItem('tokenFavorite')
      : '',
  )
  data.append('producto_sku', payload.sku)
  data.append('color', payload.color)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/whislist/delete_product',
    data: data,
  })
    .then(response => {
      if (response.data.productos) {
        state.productsFavorite = response.data.productos
        window.localStorage.setItem(
          'tokenFavorite',
          response.data.whislist.token,
        )
      } else {
        window.localStorage.removeItem('tokenFavorite')
        state.productsFavorite = null
      }
    })
    .catch(e => {
      state.errorAddproductToFavorite = true
      if (e.response.data.message) {
        state.msgErrorAddProductToFavorite = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorAddProductToFavorite = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function getProductsFavorite({ state }) {
  $store.state.loaderClover = true
  state.errorAddproductToFavorite = false
  state.msgErrorAddProductToFavorite = ''
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenFavorite')
      ? window.localStorage.getItem('tokenFavorite')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/whislist/get_products',
    data: data,
  })
    .then(response => {
      state.productsFavorite = response.data.productos
      window.localStorage.setItem('tokenFavorite', response.data.whislist.token)
    })
    .catch(e => {
      state.errorAddproductToFavorite = true
      if (e.response.data.message) {
        state.msgErrorAddProductToFavorite = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorAddProductToFavorite = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

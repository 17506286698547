var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-product-single","centered":"","hide-header":"","hide-footer":""},model:{value:(_vm.showmodal),callback:function ($$v) {_vm.showmodal=$$v},expression:"showmodal"}},[(_vm.productModal)?_c('div',[_c('router-link',{attrs:{"to":{
        name: 'ProductDetail',
        params: { url: _vm.productModal.url.replace('product-', '') },
      }}},[_c('img',{staticClass:"img-product",attrs:{"src":_vm.productModal.referencia.imagenes[0],"alt":_vm.productModal.descripcion}})]),_c('div',{staticClass:"container-price"},[(parseInt(_vm.productModal.precio_antes) > 0)?_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.productModal.precio_antes)))]):_vm._e(),_c('span',[_c('b',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.productModal.precio)))])])]),_c('router-link',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"to":{
        name: 'ProductDetail',
        params: { url: _vm.productModal.url.replace('product-', '') },
      }}},[_c('p',{staticClass:"two-wrap-text",staticStyle:{"min-height":"auto"}},[_vm._v(" "+_vm._s(_vm.productModal.producto_titulo)+" ")])]),_c('button',{staticClass:"bnt-primary w-100",on:{"click":function($event){return _vm.sendSoliAddProduct()}}},[_vm._v(" Añadir $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.productModal.precio))+" ")]),(_vm.errorAddToCart)?_c('div',{staticClass:"my-2"},[_c('span',{staticClass:"text-error"},[_vm._v(_vm._s(_vm.msgErrorAddToCart))])]):_vm._e(),_c('svg',{staticClass:"icon-close-modal",attrs:{"id":"Shopicons_Light_Close","xmlns":"http://www.w3.org/2000/svg","width":"16.325","height":"16.325","viewBox":"0 0 16.325 16.325"},on:{"click":function($event){_vm.showmodal = false}}},[_c('path',{attrs:{"id":"Trazado_4945","data-name":"Trazado 4945","d":"M0,0H16.325V16.325H0Z","fill":"none"}}),_c('g',{attrs:{"id":"Shopicon","transform":"translate(2.24 2.24)"}},[_c('path',{attrs:{"id":"Trazado_4946","data-name":"Trazado 4946","d":"M7.067,18.431l5.442-5.442,5.442,5.442.481-.481-5.442-5.442,5.442-5.442-.481-.481-5.442,5.442L7.067,6.586l-.481.481,5.442,5.442L6.586,17.95Z","transform":"translate(-6.586 -6.586)"}})])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-modal
    id="modal-product-single"
    v-model="showmodal"
    centered
    hide-header
    hide-footer
  >
    <div v-if="productModal">
      <router-link
        :to="{
          name: 'ProductDetail',
          params: { url: productModal.url.replace('product-', '') },
        }"
      >
        <img
          class="img-product"
          :src="productModal.referencia.imagenes[0]"
          :alt="productModal.descripcion"
        />
      </router-link>
      <div class="container-price">
        <del v-if="parseInt(productModal.precio_antes) > 0"
          >${{
            new Intl.NumberFormat('de-DE').format(productModal.precio_antes)
          }}</del
        >
        <span
          ><b
            >${{
              new Intl.NumberFormat('de-DE').format(productModal.precio)
            }}</b
          ></span
        >
      </div>
      <router-link
        style="text-decoration: none; color: black"
        :to="{
          name: 'ProductDetail',
          params: { url: productModal.url.replace('product-', '') },
        }"
      >
        <p class="two-wrap-text" style="min-height: auto">
          {{ productModal.producto_titulo }}
        </p>
      </router-link>
      <button class="bnt-primary w-100" @click="sendSoliAddProduct()">
        Añadir ${{ new Intl.NumberFormat('de-DE').format(productModal.precio) }}
      </button>
      <div v-if="errorAddToCart" class="my-2">
        <span class="text-error">{{ msgErrorAddToCart }}</span>
      </div>
      <svg
        id="Shopicons_Light_Close"
        class="icon-close-modal"
        xmlns="http://www.w3.org/2000/svg"
        width="16.325"
        height="16.325"
        viewBox="0 0 16.325 16.325"
        @click="showmodal = false"
      >
        <path
          id="Trazado_4945"
          data-name="Trazado 4945"
          d="M0,0H16.325V16.325H0Z"
          fill="none"
        />
        <g id="Shopicon" transform="translate(2.24 2.24)">
          <path
            id="Trazado_4946"
            data-name="Trazado 4946"
            d="M7.067,18.431l5.442-5.442,5.442,5.442.481-.481-5.442-5.442,5.442-5.442-.481-.481-5.442,5.442L7.067,6.586l-.481.481,5.442,5.442L6.586,17.95Z"
            transform="translate(-6.586 -6.586)"
          />
        </g>
      </svg>
    </div>
  </b-modal>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      showmodal: false,
    }
  },
  computed: {
    ...mapState('products', ['productModal']),
    ...mapState('cart', ['errorAddToCart', 'msgErrorAddToCart']),
  },
  watch: {
    showmodal() {
      if (!this.showmodal) {
        this.$store.state.products.productModal = null
      }
    },
    $route() {
      this.showmodal = false
    },
  },
  methods: {
    ...mapActions('cart', ['addProductTocart']),
    async sendSoliAddProduct() {
      console.log('Ajá')
      let data = {
        sku: this.productModal.producto_sku,
        color: this.productModal.referencia.color_id,
        tallaId: this.productModal.referencia.tallas[0].id,
      }
      await this.addProductTocart(data).then(() => {
        if (!this.errorAddToCart) {
          this.$bvModal.show('modal-add-to-cart')
          this.$bvModal.hide('modal-product-single')
          sendFBQEvent(this, 'AddToCart', {
            product_id: this.productModal.producto_sku,
            value: this.productModal.precio,
            content_type: 'product',
            content_name:
              this.productModal.categoria_slug.toUpperCase() + ' CLOVER',
            content_category: this.productModal.categoria_slug.toUpperCase(),
            content_ids:
              this.productModal.producto_sku +
              this.productModal.referencia.color_id +
              this.productModal.referencia.tallas[0].id,
            currency: 'COP',
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
#modal-product-single {
  .modal-dialog {
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    .modal-body {
      padding-top: 1.5rem;
    }
  }
  .img-product {
    width: 100%;
  }
  .container-price {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    span {
      padding-left: 0.5rem;
    }
    del {
      font-family: 'Open Sans bold';
    }
  }
  .icon-close-modal {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    cursor: pointer;
  }
}
</style>

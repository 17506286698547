import {
  getProductGA4,
  getGroupProductsCartGA4,
  getProductsCart,
  getInfoCheckout,
  getInfoCheckoutShipping,
  getInfoPayment,
  getDataPurchase,
  getInfoCheckoutShippingAnonymous,
  getInfoCheckoutAnonymous,
  getInfoPaymentAnonymous,
  getDataPurchaseAnonymous,
} from '@/GA4/methods'

function clearDataLayer() {
  window.dataLayer.push({ ecommerce: null })
}

export async function GA4ViewPromotion(promotion) {
  clearDataLayer()
  window.dataLayer.push({
    event: 'view_promotion',
    ecommerce: {
      creative_name: promotion.nameCreative,
      creative_slot: promotion.slot,
      promotion_id: promotion.id,
      promotion_name: promotion.name,
      items: promotion.items,
    },
  })
}

export async function GA4SelectPromotion(promotion) {
  clearDataLayer()
  window.dataLayer.push({
    event: 'select_promotion',
    ecommerce: {
      creative_name: promotion.nameCreative,
      creative_slot: promotion.slot,
      promotion_id: promotion.id,
      promotion_name: promotion.name,
      items: promotion.items,
    },
  })
}

export async function GA4ViewItemList(listProducts, vitrinaId, nameVitrina) {
  clearDataLayer()
  const productsGA4 = []
  listProducts.forEach(product => {
    productsGA4.push({
      item_name: product.producto_titulo,
      item_id: product.producto_sku,
      price: parseInt(product.producto_precio),
      affiliation: 'Clover',
      item_brand: product.marca,
      item_category: product.categoria,
      item_category2: product.subcategoria,
      item_list_id: vitrinaId,
      item_variant: product.color,
      discount:
        parseInt(product.producto_precio_antes) > 0
          ? parseInt(product.producto_precio_antes) -
            parseInt(product.producto_precio)
          : 0,
      promotion_name: product.descuento ? product.descuento : '',
      quantity: 1,
    })
  })
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: vitrinaId,
      item_list_name: nameVitrina,
      items: productsGA4,
    },
  })
}

export async function GA4SelectItem(vitrinaId, nameVitrina) {
  clearDataLayer()
  const ecommerce = await getProductGA4()
  ecommerce.item_list_id = vitrinaId
  ecommerce.item_list_name = nameVitrina
  ecommerce.items[0].item_list_id = vitrinaId
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: ecommerce,
  })
}

export async function GA4SignUp(suscription) {
  clearDataLayer()
  window.dataLayer.push({
    event: 'sign_up',
    ecommerce: {
      method: suscription,
    },
  })
}

export async function GA4Login(suscription) {
  clearDataLayer()
  window.dataLayer.push({
    event: 'login',
    ecommerce: {
      method: suscription,
    },
  })
}

export async function GA4ViewItemModal(product) {
  clearDataLayer()
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: product.titulo,
          item_id: product.producto_sku,
          price: parseInt(product.producto_precio),
          item_brand: product.marca,
          affiliation: 'Clover',
          item_category: product.genero,
          item_category2: product.categoria,
          item_category3: product.subcategoria,
          promotion_name: product.descuento ? product.descuento : '',
          discount: parseInt(product.precio_antes)
            ? parseInt(product.precio_antes) - parseInt(product.producto_precio)
            : 0,
          item_variant: product.color,
          quantity: 1,
          currency: 'COP',
        },
      ],
      currency: 'COP',
      value: parseInt(product.producto_precio),
    },
  })
}

export async function GA4ViewItem() {
  clearDataLayer()
  const ecommerce = await getProductGA4()
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: ecommerce,
  })
}

export async function GA4AddToCart(sku) {
  clearDataLayer()
  const ecommerce = await getGroupProductsCartGA4(sku)
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: ecommerce,
  })
}

export async function GA4DelToCart(product) {
  clearDataLayer()
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: { items: [product] },
  })
}

export async function GA4ViewCart() {
  clearDataLayer()
  const itemsCart = await getProductsCart()
  window.dataLayer.push({
    event: 'view_cart',
    ecommerce: { items: itemsCart },
  })
}

export async function GA4CheckoutBegin() {
  clearDataLayer()
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: await getInfoCheckout(),
  })
}

export async function GA4AddShiping() {
  clearDataLayer()
  window.dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: await getInfoCheckoutShipping(),
  })
}

export async function GA4AddPayment(typePayment) {
  clearDataLayer()
  window.dataLayer.push({
    event: 'add_payment_info',
    ecommerce: await getInfoPayment(typePayment),
  })
}

export async function GA4Purchase() {
  clearDataLayer()
  const ecommerce = await getDataPurchase()
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: ecommerce,
  })
}

export async function GA4CheckoutBeginAnonymous() {
  clearDataLayer()
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: await getInfoCheckoutAnonymous(),
  })
}

export async function GA4AddShipingAnonymous() {
  clearDataLayer()
  window.dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: await getInfoCheckoutShippingAnonymous(),
  })
}

export async function GA4AddPaymentAnonymous(typePayment) {
  clearDataLayer()
  window.dataLayer.push({
    event: 'add_payment_info',
    ecommerce: await getInfoPaymentAnonymous(typePayment),
  })
}

export async function GA4PurchaseAnonymous() {
  clearDataLayer()
  const ecommerce = await getDataPurchaseAnonymous()
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: ecommerce,
  })
}

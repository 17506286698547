import Vue from 'vue'
import $store from '../../store/index'

export async function validateDocClient({ state }, payload) {
  $store.state.loaderClover = true
  state.errorInfoCupo = false
  state.msgErrorInfocupo = ''
  state.infoValidateDoc = null
  let data = new FormData()
  data.append('documento', payload.documento)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cupopersonal/accountStatus/validateDocument',
    data: data,
  })
    .then(response => {
      state.infoValidateDoc = response.data
    })
    .catch(e => {
      state.errorInfoCupo = true
      if (e.response.data.message) {
        state.msgErrorInfocupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorInfocupo = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function validateDocClientEstadoCuenta({ state }, payload) {
  $store.state.loaderClover = true
  state.errorInfoCupo = false
  state.msgErrorInfocupo = ''
  state.infoValidateDoc = null
  let data = new FormData()
  data.append('documento', payload.documento)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cupopersonal/accountStatus/generateToken',
    data: data,
  })
    .then(response => {
      state.infoValidateDoc = response.data
    })
    .catch(e => {
      state.errorInfoCupo = true
      if (e.response.data.message) {
        state.msgErrorInfocupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorInfocupo = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function validateTelClientEstadoCuenta({ state }, payload) {
  $store.state.loaderClover = true
  state.errorInfoCupo = false
  state.msgErrorInfocupo = ''
  let data = new FormData()
  data.append('celular', payload.celular)
  data.append('documento', payload.documento)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cupopersonal/accountStatus/validatePhone',
    data: data,
  })
    .catch(e => {
      state.errorInfoCupo = true
      if (e.response.data.message) {
        state.msgErrorInfocupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorInfocupo = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

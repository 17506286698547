import Vue from 'vue'
import {
  BFormRating,
  BPagination,
  BSkeletonImg,
  BSkeleton,
  NavPlugin,
  SidebarPlugin,
  BPopover,
  BSidebar,
  ModalPlugin,
  FormGroupPlugin,
  TablePlugin,
  PopoverPlugin,
  VBHoverPlugin,
  BCard,
  CollapsePlugin,
  LinkPlugin,
  FormRatingPlugin,
  IconsPlugin,
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(LinkPlugin)
Vue.use(NavPlugin)
Vue.use(IconsPlugin)
Vue.use(ModalPlugin)
Vue.use(TablePlugin)
Vue.use(PopoverPlugin)
Vue.use(VBHoverPlugin)
Vue.use(SidebarPlugin)
Vue.use(CollapsePlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormRatingPlugin)
Vue.component('b-card', BCard)
Vue.component('b-sidebar', BSidebar)
Vue.component('b-popover', BPopover)
Vue.component('b-skeleton', BSkeleton)
Vue.component('b-pagination', BPagination)
Vue.component('b-form-rating', BFormRating)
Vue.component('b-skeleton-img', BSkeletonImg)

import Vue from 'vue'
import $store from '../../store/index'
import $router from '../../router/index'

export function getProductsCategory({ state }, payload) {
  $store.state.loaderClover = true
  state.productsCategory = null
  state.infoCatalogo = null
  state.estrategiaCatlogo = null
  state.productsAnalytics = null
  // let urlSlug = payload.category + (payload.subcategory ? '-' + payload.subcategory : '') + (payload.wildCard ? '-' + payload.wildCard : '')
  // if (!urlSlug) {
  //     urlSlug = 'store'
  // }
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/productos/catalogo/' + payload.slug + payload.query,
  })
    .then(response => {
      state.productsCategory = response.data.productos
      state.infoCatalogo = response.data.info_catalogo
      state.estrategiaCatlogo = response.data.estrategia_venta
      state.dataCatalogoAnalytics = response.data.productos_analytics
    })
    .catch(e => {
      console.log(e)
      if (e.response.status == 404) {
        $router.replace({
          name: 'Error',
          params: {
            pathError: location.pathname.replace('/', '') + location.search,
          },
        })
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
      console.log('getProductsCategory Finish')
    })
}

export function getFiltersCategory({ state }, payload) {
  $store.state.loaderClover = true
  state.productsCategory = null
  state.infoCatalogo = null
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/productos/filtros/catalogo/' + payload.slug + payload.query,
  })
    .then(response => {
      state.filters = response.data
    })
    .catch(e => {
      console.log(e)
      if (e.response.status == 404) {
        $router.replace({
          name: 'Error',
          params: {
            pathError: location.pathname + location.search,
          },
        })
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
      console.log('getFiltersCategory Finish')
    })
}

export function getRelacionados({ state }, payload) {
  state.vitrinaRelacionados = null
  var form = new FormData()
  form.append('skus', payload.sku)
  Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/productos/relacionados',
    data: form,
  })
    .then(response => {
      state.vitrinaRelacionados = response.data
    })
    .catch(e => {
      console.log(e)
      if (e.response.status == 404) {
        $router.replace({
          name: 'Error',
          params: {
            pathError: $router.history._startLocation.replace('/', ''),
          },
        })
      }
    })
    .finally(() => {
      console.log('getRelacionados Finish')
    })
}

export function getMasVisto({ state }) {
  state.vitrinaMasVisto = null
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/productos/vitrinas/vitrina/1',
  })
    .then(response => {
      state.vitrinaMasVisto = response.data.vitrina.items
    })
    .catch(e => {
      console.log(e)
      if (e.response.status == 404) {
        $router.replace({
          name: 'Error',
          params: {
            pathError: $router.history._startLocation.replace('/', ''),
          },
        })
      }
    })
    .finally(() => {
      console.log('getProductsCategory Finish')
    })
}

export function getProduct({ state }, payload) {
  state.productDetail = null
  $store.state.loaderClover = true
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: `/productos/producto/detalle/${payload.url}`,
  })
    .then(response => {
      state.productDetail = response.data.producto
    })
    .catch(e => {
      console.log(e)
      if (e.response.status == 404) {
        $router.replace({
          name: 'Error',
          params: {
            pathError: $router.history._startLocation.replace('/', ''),
          },
        })
      }
    })
    .finally(() => {
      console.log('getProductsCategory Finish')
      $store.state.loaderClover = false
    })
}

export function getDisponibilidad({ state }, payload) {
  state.disponibilidadProduct = null
  var form = new FormData()
  form.append('producto_sku', payload.sku)
  form.append('color', payload.color)
  form.append('talla', payload.talla)
  Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/productos/bodegas/disponibilidad',
    data: form,
  })
    .then(response => {
      state.disponibilidadProduct = response.data
    })
    .catch(e => {
      console.log(e)
      if (e.response.status == 404) {
        $router.replace({
          name: 'Error',
          params: {
            pathError: $router.history._startLocation.replace('/', ''),
          },
        })
      }
    })
    .finally(() => {
      console.log('getDisponibilidad Finish')
    })
}

export function addValoracion({ state }, payload) {
  state.disponibilidadProduct = null
  state.errorvaloracion = false
  var form = new FormData()
  form.append('puntaje', payload.puntaje)
  form.append('comentario', payload.comentario)
  form.append('producto_sku', payload.sku)
  form.append('color', payload.color)
  form.append('nombre', payload.nombre)
  form.append('correo', payload.correo)
  Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/productos/producto/valoraciones/registrar',
    data: form,
  })
    .catch(() => {
      state.errorvaloracion = true
    })
    .finally(() => {
      console.log('getRelacionados Finish')
    })
}

export function getValoraciones({ state }, payload) {
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/productos/producto/valoraciones/' + payload.sku,
  }).catch(response => {
    state.valoracionesProducto = response.data.valoraciones
  })
}

export function calculateShipping({ state }, payload) {
  $store.state.loaderClover = true
  state.disponibilidadProduct = null
  state.errorvaloracion = false
  var form = new FormData()
  state.infoEnvio = null
  form.append('departamento_codigo', payload.dep)
  form.append('ciudad_codigo', payload.ciu)
  Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/envios/calcular_envio',
    data: form,
  })
    .then(response => {
      state.infoEnvio = response.data
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

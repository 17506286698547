import Vue from 'vue'
import $store from '../../store/index'

export async function sendSoliNewsLatter({ state }, payload) {
  $store.state.loaderClover = true
  state.errorSolicitud = false
  state.msgErrorSolicitud = ''
  let data = new FormData()
  data.append('email', payload.email)
  data.append('nombres', payload.name)
  data.append('dia_cumpleanos', payload.day)
  data.append('mes_cumpleanos', payload.month)
  data.append('sms', payload.sendSMS)
  data.append('telefono', payload.tel)
  data.append('genero', 'F')
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/newsletter/registro',
    data: data,
  })
    .catch(e => {
      state.errorSolicitud = true
      if (e.response.data.message) {
        state.msgErrorSolicitud = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorSolicitud = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

// ---||  [ obtener info newslatter ]  ||--- //
export async function getInfoNewsLatter({ state }) {
  await Vue.axios({
    method: 'GET',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    url: '/layout/get_descuento_newsletter',
  }).then(response => {
    state.tituloNewslatter = response.data.data.titulo
    state.descripcionNewslatter = response.data.data.descripcion
  })
}

import state from './state'
import * as actions from './actions'

const namespaced = true

export default {
  namespaced,
  actions,
  state,
}

<template>
  <div id="app">
    <component :is="menuLayoutClover"></component>
    <div id="content-view__id" class="content-view">
      <router-view />
    </div>
    <boton-whatsapp v-if="footerLayoutClover == 'footer-clover'" />
    <component :is="footerLayoutClover"></component>
    <modal-login />
    <modal-add-to-cart />
    <modal-cupo />
    <loader-clover v-if="loaderClover" />
    <modal-product />
    <modal-news-latter-done />
    <modal-sold-out />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'

import ModalAddToCart from '@/components/Templates/ModalAddToCart'
import MenuClover from '@/components/menu/MenuClover'
import MenuLayoutSimple from '@/components/menu/MenuLayoutSimple'
import FooterClover from '@/components/footer/FooterClover'
import FooterCloverSimple from '@/components/footer/FooterCloverSimple'
import ModalLogin from '@/components/Templates/ModalLogin'
import LoaderClover from '@/components/Templates/LoaderClover.vue'
import ModalCupo from '@/components/Templates/ModalCupo.vue'
import ModalProduct from '@/components/Templates/ModalProduct.vue'
import ModalNewsLatterDone from '@/components/Templates/ModalNewsLatterDone.vue'
import BotonWhatsapp from '@/components/Templates/BotonWhatsapp.vue'
import ModalSoldOut from '@/components/bag/ModalSoldOut'

export default {
  name: 'App',
  components: {
    MenuClover,
    FooterClover,
    FooterCloverSimple,
    MenuLayoutSimple,
    ModalLogin,
    ModalAddToCart,
    LoaderClover,
    ModalCupo,
    ModalProduct,
    ModalNewsLatterDone,
    BotonWhatsapp,
    ModalSoldOut,
  },
  computed: {
    ...mapState('layouts', ['menuLayoutClover', 'footerLayoutClover']),
    ...mapState(['loaderClover', 'innerWidthPage']),
    ...mapState('cart', ['productsCart']),
  },
  watch: {
    $route() {
      sendFBQEvent(this, 'PageView', {})
      this.evalMtLayoutBody()
      if (window.localStorage.getItem('accessToken')) {
        this.validateAuth()
        this.updateLayout += 2
      }
    },
    menuLayoutClover() {
      this.evalMtLayoutBody()
    },
    productsCart() {
      if (this.productsCart && this.productsCart.find(p => p.status == 0)) {
        this.$bvModal.show('modal-products-sold-out')
      }
    },
  },
  mounted() {
    this.getTopbar()
    this.$store.state.innerWidthPage = window.innerWidth
    window.onresize = () => {
      this.$store.state.innerWidthPage = window.innerWidth
      this.evalMtLayoutBody()
    }
    if (window.localStorage.getItem('accessToken')) {
      this.getDataProfile()
    }
    this.getMenu()
    if (window.localStorage.getItem('tokenCart')) {
      this.getProductsCart()
    }
    if (window.localStorage.getItem('tokenFavorite')) {
      this.getProductsFavorite()
    }
    sendFBQEvent(this, 'PageView', {})
  },
  created() {
    this.$analytics.fbq.init('5684106381629173', {})
  },
  methods: {
    ...mapActions('user', ['validateAuth', 'getDataProfile']),
    ...mapActions('layouts', ['getMenu', 'getTopbar']),
    ...mapActions('cart', ['getProductsCart']),
    ...mapActions('favorite', ['getProductsFavorite']),
    evalMtLayoutBody() {
      let heightMenu = document.getElementById('menu-clover')
      let contentView = document.getElementById('content-view__id')
      if (
        (this.menuLayoutClover == 'menu-clover' ||
          this.innerWidthPage != window.innerWidth) &&
        contentView
      ) {
        contentView.style.marginTop = heightMenu
          ? heightMenu.offsetHeight + 'px'
          : '120px'
      } else {
        contentView.style.marginTop = '0px'
      }
    },
  },
}
</script>

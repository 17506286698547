import Vue from 'vue'
import $store from '../../store/index'

export async function getHome({ state }) {
  $store.state.loaderClover = true
  await Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/home/get_home',
  })
    .then(response => {
      state.dataHome = response.data.home
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function getTopbar({ state }) {
  $store.state.loaderClover = true
  await Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/layout/get_topbar',
  })
    .then(response => {
      state.dataTopbar = response.data.topbar
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function getMenu({ state }) {
  // $store.state.loaderClover = true;
  await Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/layout/get_menu',
  })
    .then(response => {
      state.menu = response.data.items
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      // $store.state.loaderClover = false;
    })
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-add-to-cart","size":"lg","centered":"","hide-header":"","hide-footer":""},model:{value:(_vm.modalAddProduct),callback:function ($$v) {_vm.modalAddProduct=$$v},expression:"modalAddProduct"}},[(_vm.productsCart)?_c('div',{staticClass:"container-modal"},[_c('svg',{attrs:{"id":"Shopicons_Light_Close","xmlns":"http://www.w3.org/2000/svg","width":"16.325","height":"16.325","viewBox":"0 0 16.325 16.325"},on:{"click":function($event){return _vm.$bvModal.hide('modal-add-to-cart')}}},[_c('path',{attrs:{"id":"Trazado_4945","data-name":"Trazado 4945","d":"M0,0H16.325V16.325H0Z","fill":"none"}}),_c('g',{attrs:{"id":"Shopicon","transform":"translate(2.24 2.24)"}},[_c('path',{attrs:{"id":"Trazado_4946","data-name":"Trazado 4946","d":"M7.067,18.431l5.442-5.442,5.442,5.442.481-.481-5.442-5.442,5.442-5.442-.481-.481-5.442,5.442L7.067,6.586l-.481.481,5.442,5.442L6.586,17.95Z","transform":"translate(-6.586 -6.586)"}})])]),_c('div',{staticClass:"modal-info-product-content"},[_c('h5',[_vm._v("Producto añadido a tu bolsa")]),_c('div',{staticClass:"content-product-modal-add-to-cart"},[_c('img',{staticClass:"mb-auto",attrs:{"src":_vm.productsCart[0].referencia.imagenes[0],"alt":_vm.productsCart[0].producto_titulo
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())}}),_c('div',{staticClass:"data-product-modal-add-to-cart col"},[_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.productsCart[0].producto_titulo .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),_c('p',{staticClass:"mb-0 text-gray"},[_vm._v(" SKU: "+_vm._s(_vm.productsCart[0].producto_sku)+" ")]),_c('p',{staticClass:"mb-2 text-gray"},[_vm._v(" Color: "+_vm._s(_vm.productsCart[0].referencia.color_nombre)+" - Talla: "+_vm._s(_vm.productsCart[0].referencia.talla.name)+" ")]),_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.productsCart[0].precio)))])])])])]),_c('div',{staticClass:"actions-modal"},[_c('p',{staticClass:"text-center text-gray"},[_vm._v(" Tienes "+_vm._s(_vm.productsCart ? _vm.productsCart.length : 0)+" "+_vm._s(_vm.productsCart && _vm.productsCart.length > 1 ? 'productos' : 'producto')+" en tu carrito ")]),_c('button',{staticClass:"bnt-outline w-100 mb-4",staticStyle:{"border-radius":"5px"},on:{"click":function($event){return _vm.$bvModal.hide('modal-add-to-cart')}}},[_vm._v(" Seguir comprando ")]),_c('button',{staticClass:"btn-primary-clover w-100",on:{"click":function($event){return _vm.$router.push({ name: 'Bag' })}}},[_vm._v(" Finalizar compra ")])]),(_vm.vptCart)?_c('div',{staticClass:"carousel-modal"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Esto te puede gustar")]),_c('carousel',{staticClass:"content-carousel-modal-add-to-cart",attrs:{"dots":false,"lazy-load":true,"nav":false,"responsive":{
          0: { items: 1, nav: false, stagePadding: 0 },
          500: { items: 2, nav: false, stagePadding: 0 },
          768: { items: 2, nav: false, stagePadding: 0 },
          991: { items: 4, nav: false, stagePadding: 0 },
        }}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"prev nav-button"},[_c('img',{attrs:{"src":require("@/assets/icons/left-carousel.svg"),"alt":"left arrow carousel"}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"next nav-button"},[_c('img',{attrs:{"src":require("@/assets/icons/right-carousel.svg"),"alt":"right arrow carousel"}})])]),_vm._l((_vm.vptCart.productos),function(product,index){return _c('div',{key:index,staticClass:"card-product-modal"},[_c('div',{staticClass:"content-card-product-modal",on:{"click":function($event){return _vm.showProduct(product)}}},[_c('img',{attrs:{"src":product.referencia.imagenes[0]}}),_c('p',{staticClass:"name-product-modal two-wrap-text",staticStyle:{"min-height":"auto"}},[_vm._v(" "+_vm._s(product.producto_titulo .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),_c('p',{staticClass:"price-product-modal"},[_c('b',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(product.precio)))])])])])})],2)],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  dataCart: null,
  loaderBag: false,
  productsCart: null,
  vptCart: null,
  productsEstrategia: null,
  errorAddToCart: false,
  msgErrorAddToCart: '',
  dataSummaryPayment: '',
  btnModalExcepcion406: '',
  msgModalExcepcion406: '',
  msgErrorSurvey: '',
  errorSurvey: false,
  errorAddCuponCard: false,
  msgErrorAddCuponCard: '',
  errorCupon: false,
  msgErrorCupon: '',
}

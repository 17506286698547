<template>
  <nav id="menu-clover">
    <div class="content-header-topbar">
      <div class="topbar__content py-0 col">
        <div v-if="dataTopbar" class="w-100" v-html="dataTopbar.html"></div>
      </div>
      <div class="options-header-topbar d-none d-md-flex">
        <p>
          <router-link :to="{ name: 'Tiendas' }">Tiendas</router-link>
        </p>
        <p>
          <router-link :to="{ name: 'RastrearPedido' }"
            >Rastrear pedido</router-link
          >
        </p>
        <p>
          <a href="https://portalpagos.cloverbolsos.co">Pagar cuota</a>
        </p>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center w-100 content-max-width"
      style="min-height: 80px"
    >
      <div class="nav-items">
        <div
          class="nav-logo d-flex d-md-none pe-4 my-auto"
          @click="showMenuMovil = !showMenuMovil"
        >
          <img
            v-show="!showMenuMovil"
            alt="Menu logo"
            src="@/assets/icons/menu-movil-icon.svg"
          />
          <img
            v-show="showMenuMovil"
            alt="Menu logo"
            src="@/assets/icons/menu-movil-icon-close.svg"
          />
        </div>
        <router-link :to="{ name: 'Home' }" class="nav-logo">
          <img alt="Logo clover" src="@/assets/icons/logo-clover.svg" />
          <!-- <img alt="Logo clovy" src="@/assets/icons/logo_clovy.svg"> -->
        </router-link>
        <div class="d-none d-md-flex">
          <router-link
            v-for="(item, index) in menu"
            :key="index"
            :to="{ name: item.routerName, params: { collection: item.slug } }"
            class="nav-item"
          >
            {{
              item.descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </router-link>
        </div>
      </div>
      <div class="nav-icons">
        <div class="nav-icon">
          <div :class="tokenUser ? 'have-user' : ''">
            <img
              src="@/assets/icons/account.svg"
              style="cursor: pointer"
              @click="showClickPerfil = !showClickPerfil"
            />
          </div>
          <div
            v-if="showClickPerfil"
            id="bg-perfil__modal"
            class="bg-perfil-modal"
            @click="showClickPerfil = false"
          >
            <div class="container-doing-perfil">
              <a
                class="container-cupo"
                href="https://preaprobado.quac.co/?store_business=baguer&slug_store=clover"
                target="_blank"
              >
                Solicita tu crédito
              </a>
              <div class="links-destacados">
                <router-link
                  class="link-destacado"
                  :to="{ name: 'RastrearPedido' }"
                  >Rastrear tu pedido</router-link
                >
                <a
                  href="https://portalpagos.cloverbolsos.co"
                  class="link-destacado"
                >
                  Paga tu cuota
                </a>
                <router-link
                  class="link-destacado"
                  :to="{ name: 'Devoluciones' }"
                  >Devoluciones</router-link
                >
              </div>
              <div class="btns-actions">
                <button
                  style="border-right: 1px solid #fef5f5"
                  @click="
                    tokenUser
                      ? $router.push({ name: 'Profile' }).catch(() => {})
                      : [
                          $bvModal.show('modal-login'),
                          ($store.state.user.isLogin = true),
                        ]
                  "
                >
                  {{ tokenUser ? 'Ver perfil' : 'Iniciar sesión' }}
                </button>
                <button
                  style="border-left: 1px solid #fef5f5"
                  @click="
                    tokenUser
                      ? logOut()
                      : [
                          $bvModal.show('modal-login'),
                          ($store.state.user.isLogin = false),
                        ]
                  "
                >
                  {{ tokenUser ? 'Cerrrar sesión' : 'Registrarme' }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="search-input" class="nav-icon" @click="clickSearch()">
          <img src="@/assets/icons/busqueda.svg" alt="" />
        </div>
        <router-link :to="{ name: 'PerfilFavorites' }" class="nav-icon">
          <div :class="productsFavorite ? 'have-user' : ''">
            <img src="@/assets/icons/favoritos.svg" alt="" />
          </div>
        </router-link>
        <router-link :to="{ name: 'Bag' }" class="nav-icon pe-0">
          <div :class="productsCart ? 'have-user' : ''">
            <img src="@/assets/icons/bolsa.svg" alt="" />
          </div>
        </router-link>
      </div>
      <div
        id="menu-movil-slide"
        class="menu-movil"
        :class="showMenuMovil ? 'show-menu-movil' : ''"
      >
        <div>
          <router-link
            v-for="(item, index) in menu"
            :key="index"
            :to="{ name: item.routerName, params: { collection: item.slug } }"
            class="nav-item-movil"
          >
            {{
              item.descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </router-link>
          <router-link :to="{ name: 'Tiendas' }" class="nav-item-movil">
            Tiendas
          </router-link>
          <router-link :to="{ name: 'RastrearPedido' }" class="nav-item-movil">
            Rastrear pedido
          </router-link>
          <a href="https://portalpagos.cloverbolsos.co" class="nav-item-movil">
            Pagar cuota
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      showMenuMovil: false,
      tokenUser: null,
      showClickPerfil: false,
    }
  },
  computed: {
    ...mapState('layouts', ['menuLayoutClover', 'menu', 'dataTopbar']),
    ...mapState('user', ['dataUser', 'errorLogin']),
    ...mapState('favorite', ['productsFavorite']),
    ...mapState('cart', ['productsCart']),
  },
  watch: {
    showMenuMovil() {
      let slideMenu = document.getElementById('menu-movil-slide')
      slideMenu.style.transition = 'all .2s ease-in-out'
      slideMenu
        ? (slideMenu.style.top =
            document.getElementById('menu-clover').offsetHeight + 'px')
        : ''
    },
    $route() {
      this.showMenuMovil = false
      this.showClickPerfil = false
    },
    dataUser() {
      if (this.dataUser) {
        this.tokenUser = window.localStorage.getItem('accessToken')
      } else {
        this.tokenUser = null
      }
    },
    async showClickPerfil() {
      await setTimeout(() => {
        let heightMenu = document.getElementById('menu-clover')
        let bgPerfilModal = document.getElementById('bg-perfil__modal')
        if (bgPerfilModal && heightMenu) {
          bgPerfilModal.style.top = `${
            heightMenu ? heightMenu.offsetHeight : 120
          }px`
        }
      }, 1)
    },
  },
  mounted() {
    if (window.localStorage.getItem('accessToken')) {
      if (this.dataUser) {
        this.tokenUser = window.localStorage.getItem('accessToken')
      }
    }
    var prevScrollpos = window.pageYOffset
    window.document.onscroll = () => {
      this.$store.state.innerWidthPage = window.innerWidth
      var currentScrollPos = window.pageYOffset
      if (
        this.menuLayoutClover == 'menu-clover' &&
        !this.showClickPerfil &&
        !this.showMenuMovil
      ) {
        let navBar = document.getElementById('menu-clover')
        let slideMenu = document.getElementById('menu-movil-slide')
        let containerDataProduct = document.getElementById(
          'container-data-detail-product',
        )
        if (currentScrollPos < prevScrollpos) {
          if (window.pageYOffset > 80) {
            navBar.style.position = 'fixed'
            // navBar.style.backgroundColor = 'white'
            containerDataProduct
              ? (containerDataProduct.style.top =
                  document.getElementById('menu-clover').offsetHeight + 'px')
              : ''
            slideMenu ? (slideMenu.style.transition = 'all .4s linear') : ''
            slideMenu
              ? (slideMenu.style.top =
                  document.getElementById('menu-clover').offsetHeight + 'px')
              : ''
          } else {
            // navBar.style.backgroundColor = window.innerWidth < 768 ? 'white' : 'transparent'
          }
          navBar.style.top = '0px'
        } else {
          if (window.pageYOffset > 80) {
            containerDataProduct ? (containerDataProduct.style.top = 0) : ''
            navBar.style.top = `-${
              document.getElementById('menu-clover').offsetHeight
            }px`
            slideMenu ? (slideMenu.style.transition = 'all .4s linear') : ''
            slideMenu ? (slideMenu.style.top = '0') : ''
            navBar.style.position = 'fixed'
            // navBar.style.backgroundColor = window.innerWidth < 768 ? 'white' : 'transparent'
          }
        }
        prevScrollpos = currentScrollPos
      }
      let btnAddProduct = document.getElementById('btn-addToCart-desktop')
      if (btnAddProduct && window.innerWidth > 768) {
        let btnFixedAddtocart = document.getElementById('btn-fixed-addtocart')
        let footer = document.getElementById('footer-simple')
        let btnWhatsapp = document.getElementById('btn-whatsapp')
        if (btnAddProduct.offsetTop + 50 <= currentScrollPos) {
          if (footer.offsetTop <= currentScrollPos + window.innerHeight) {
            btnFixedAddtocart.style.bottom = '-100px'
            btnFixedAddtocart.style.opacity = '0'
            btnWhatsapp.style.bottom = '2rem'
          } else {
            btnFixedAddtocart.style.bottom = '0px'
            btnFixedAddtocart.style.opacity = '1'
            btnWhatsapp.style.bottom = '4rem'
          }
        } else {
          btnFixedAddtocart.style.bottom = '-100px'
          btnFixedAddtocart.style.opacity = '0'
          btnWhatsapp.style.bottom = '2rem'
        }
      } else if (window.innerWidth > 768) {
        let btnWhatsapp = document.getElementById('btn-whatsapp')
        if (btnWhatsapp) {
          btnWhatsapp.style.bottom = '2rem'
        }
      }
    }
  },
  methods: {
    ...mapActions('user', ['logOut']),
    clickSearch() {
      sendFBQEvent(this, 'Search', {})
    },
  },
}
</script>

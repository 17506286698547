export default {
  isLogin: false,
  errorUpdateInfo: false,
  msgErrorUpdateInfo: '',
  dataUser: null,
  errorDir: false,
  msgErrorDir: '',
  directions: [],
  errorLoginCod: false,
  msgErrorCod: '',
  pedidos: [],
  dataOrder: null,
  errorGetInfoOrder: false,
  msgGetInfoOrder: '',
  errorLogin: false,
  msgErrorLogin: '',
  errorRegister: false,
  msgErrorRegister: '',
  departamentos: [],
  msgErrorupdatePassword: '',
  errorUpdatePassword: false,
  updatePasswordSuccessfull: false,
  errorDevolucion: false,
  msgErrorDevolucion: '',
  infoDevolucion: null,
}

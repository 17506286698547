<template>
  <b-modal
    id="modal-add-to-cart"
    v-model="modalAddProduct"
    size="lg"
    centered
    hide-header
    hide-footer
  >
    <div v-if="productsCart" class="container-modal">
      <svg
        id="Shopicons_Light_Close"
        xmlns="http://www.w3.org/2000/svg"
        width="16.325"
        height="16.325"
        viewBox="0 0 16.325 16.325"
        @click="$bvModal.hide('modal-add-to-cart')"
      >
        <path
          id="Trazado_4945"
          data-name="Trazado 4945"
          d="M0,0H16.325V16.325H0Z"
          fill="none"
        />
        <g id="Shopicon" transform="translate(2.24 2.24)">
          <path
            id="Trazado_4946"
            data-name="Trazado 4946"
            d="M7.067,18.431l5.442-5.442,5.442,5.442.481-.481-5.442-5.442,5.442-5.442-.481-.481-5.442,5.442L7.067,6.586l-.481.481,5.442,5.442L6.586,17.95Z"
            transform="translate(-6.586 -6.586)"
          />
        </g>
      </svg>

      <div class="modal-info-product-content">
        <h5>Producto añadido a tu bolsa</h5>
        <div class="content-product-modal-add-to-cart">
          <img
            :src="productsCart[0].referencia.imagenes[0]"
            :alt="
              productsCart[0].producto_titulo
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            "
            class="mb-auto"
          />
          <div class="data-product-modal-add-to-cart col">
            <p class="mb-2">
              {{
                productsCart[0].producto_titulo
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </p>
            <p class="mb-0 text-gray">
              SKU: {{ productsCart[0].producto_sku }}
            </p>
            <p class="mb-2 text-gray">
              Color: {{ productsCart[0].referencia.color_nombre }} - Talla:
              {{ productsCart[0].referencia.talla.name }}
            </p>
            <p class="mb-2">
              <b
                >${{
                  new Intl.NumberFormat('de-DE').format(productsCart[0].precio)
                }}</b
              >
            </p>
            <!-- <div class="note-gift">
                            <svg style="margin-right: .4rem; min-width: 23px; min-height: 23px;" id="Shopicons_Light_Gift" xmlns="http://www.w3.org/2000/svg" width="22.141" height="22.141" viewBox="0 0 22.141 22.141">
                                <path id="Trazado_5027" data-name="Trazado 5027" d="M0,0H22.141V22.141H0Z" fill="none"/>
                                <g id="Shopicon" transform="translate(1.829 2.015)">
                                    <path id="Trazado_5028" data-name="Trazado 5028" d="M20.571,8.058H18.565a2.768,2.768,0,1,0-5.213,0h-.322a2.768,2.768,0,1,0-5.213,0H5.81A1.851,1.851,0,0,0,3.965,9.9V20.974A1.851,1.851,0,0,0,5.81,22.819H20.571a1.851,1.851,0,0,0,1.845-1.845V9.9A1.851,1.851,0,0,0,20.571,8.058ZM15.958,5.291a1.845,1.845,0,1,1-1.845,1.845A1.847,1.847,0,0,1,15.958,5.291Zm-5.535,0A1.845,1.845,0,1,1,8.578,7.136,1.847,1.847,0,0,1,10.423,5.291ZM4.888,9.9a.923.923,0,0,1,.923-.923H8.364a2.758,2.758,0,0,0,4.117,0h.057l-3.69,3.69.652.652,3.229-3.229v5.344H4.888V9.9Zm0,11.071V16.361h7.842V21.9H5.81A.923.923,0,0,1,4.888,20.974Zm16.606,0a.923.923,0,0,1-.923.923H13.652V16.361h7.842v-.923H13.652V10.094l3.229,3.229.652-.652-3.69-3.69H13.9a2.758,2.758,0,0,0,4.117,0h2.554a.923.923,0,0,1,.923.923Z" transform="translate(-3.965 -4.368)"/>
                                </g>
                            </svg>
                            ¡Este producto tiene una nota de regalo!
                        </div> -->
          </div>
        </div>
      </div>
      <div class="actions-modal">
        <p class="text-center text-gray">
          Tienes {{ productsCart ? productsCart.length : 0 }}
          {{
            productsCart && productsCart.length > 1 ? 'productos' : 'producto'
          }}
          en tu carrito
        </p>
        <button
          class="bnt-outline w-100 mb-4"
          style="border-radius: 5px"
          @click="$bvModal.hide('modal-add-to-cart')"
        >
          Seguir comprando
        </button>
        <button
          class="btn-primary-clover w-100"
          @click="$router.push({ name: 'Bag' })"
        >
          Finalizar compra
        </button>
      </div>
      <div v-if="vptCart" class="carousel-modal">
        <h5 class="mb-0">Esto te puede gustar</h5>
        <carousel
          class="content-carousel-modal-add-to-cart"
          :dots="false"
          :lazy-load="true"
          :nav="false"
          :responsive="{
            0: { items: 1, nav: false, stagePadding: 0 },
            500: { items: 2, nav: false, stagePadding: 0 },
            768: { items: 2, nav: false, stagePadding: 0 },
            991: { items: 4, nav: false, stagePadding: 0 },
          }"
        >
          <template slot="prev"
            ><span class="prev nav-button"
              ><img
                src="@/assets/icons/left-carousel.svg"
                alt="left arrow carousel"/></span
          ></template>
          <template slot="next"
            ><span class="next nav-button"
              ><img
                src="@/assets/icons/right-carousel.svg"
                alt="right arrow carousel"/></span
          ></template>
          <div
            v-for="(product, index) in vptCart.productos"
            :key="index"
            class="card-product-modal"
          >
            <div
              class="content-card-product-modal"
              @click="showProduct(product)"
            >
              <img :src="product.referencia.imagenes[0]" />
              <p
                class="name-product-modal two-wrap-text"
                style="min-height: auto"
              >
                {{
                  product.producto_titulo
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}
              </p>
              <p class="price-product-modal">
                <b
                  >${{
                    new Intl.NumberFormat('de-DE').format(product.precio)
                  }}</b
                >
              </p>
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </b-modal>
</template>
<script>
import carousel from 'v-owl-carousel'

import { mapState } from 'vuex'
export default {
  components: { carousel },
  data() {
    return {
      modalAddProduct: false,
    }
  },
  computed: {
    ...mapState('cart', ['dataCart', 'productsCart', 'vptCart']),
  },
  watch: {
    $route() {
      this.modalAddProduct = false
    },
  },
  methods: {
    showProduct(product) {
      this.$store.state.products.productModal = product
      setTimeout(() => {
        this.$bvModal.show('modal-product-single')
      }, 100)
    },
  },
}
</script>
<style lang="scss">
#modal-add-to-cart {
  .modal-body {
    padding: 0;
  }
  .modal-content {
    border-radius: 0;
  }
  .container-modal {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    #Shopicons_Light_Close {
      position: absolute;
      top: 0.4rem;
      right: 0.4rem;
      cursor: pointer;
      &:hover {
        #Trazado_4946 {
          stroke: #000000;
        }
      }
    }
    .modal-info-product-content {
      width: 60%;
      padding: 1rem;
      .content-product-modal-add-to-cart {
        display: flex;
        flex-wrap: wrap;
        img {
          max-width: 120px;
          margin: auto;
        }
        .data-product-modal-add-to-cart {
          padding-left: 1rem;
          .note-gift {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #ebeeff;
            font-size: 13px;
            padding: 0.3rem;
          }
        }
      }
    }
    .actions-modal {
      width: 40%;
      background-color: #f4f6f8;
      padding: 2rem;
    }
    .carousel-modal {
      width: 100%;
      padding: 1rem;
      border-top: 2px solid #f4f6f8;
      .content-carousel-modal-add-to-cart {
        width: 100%;
        position: relative;
        padding-right: 2rem;
        padding: 2rem;
        .prev {
          position: absolute;
          top: 50%;
          left: 0;
          z-index: 2;
          cursor: pointer;
          transform: translateY(-50%);
        }
        .next {
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 2;
          cursor: pointer;
          transform: translateY(-50%);
        }
        .owl-stage {
          display: flex;
        }
        .card-product-modal {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          display: flex;
          height: 100%;
          .content-card-product-modal {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            cursor: pointer;
          }
          img {
            margin-bottom: auto;
          }
          .name-product-modal {
            margin-bottom: auto;
            margin-top: 0.3rem;
            font-size: 13px;
          }
          .price-product-modal {
            font-size: 18px;
            margin-top: auto;
            margin-bottom: 0;
          }
        }
      }
    }
    @media (max-width: 991px) {
      .modal-info-product-content {
        width: 100%;
      }
      .actions-modal {
        width: 100%;
      }
    }
  }
}
</style>

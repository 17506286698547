export function sendFBQEvent(elThis, eventName, params) {
  try {
    const misCookies = document.cookie.split(';')
    let facebookCookie = ''
    for (var index = 0; index < misCookies.length; index++) {
      let busca2 = misCookies[index].search('_fbc')
      if (busca2 > -1) {
        facebookCookie = misCookies[index].split('=')[1]
      }
    }
    params.fbc = facebookCookie
    elThis.$analytics.fbq.event(eventName, params)
  } catch (error) {
    console.log('error: ', error)
  }
}
